
import { defineComponent, ref, onMounted, computed} from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { useBus } from "@/bus.ts";
import { useRoute } from "vue-router";

type ProfileImageType = {
  profile_image: string;
};

export default defineComponent({
  mixins: [apiEndpoint],
  name: "beneficiaryProfile",
  components: {},
  setup() {
    const route = useRoute();
    const beneficiaryInfo = ref<ProfileImageType | null>(null);

    const getBeneficiaryInfo = async () => {
      try {
        const beneficiaryId = route.params.id;
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_BENEFICIARY_INFO,
          {
            id: beneficiaryId,
          }
        );
        beneficiaryInfo.value = response.data.data as ProfileImageType;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const beneficiaryProfileImage = computed(() => {
      const profileImage = beneficiaryInfo.value?.profile_image ?? null;

      if (profileImage !== null) {
        return apiEndpoint.data().BEE_FILE_URL + profileImage;
      } else {
        return ""; // or provide a default image URL
      }
    });

    onMounted(() => {
      getBeneficiaryInfo();
    });

    return { beneficiaryInfo, beneficiaryProfileImage };
  },
});
